import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';

import { LAYOUT_CONTAINER_MAX_WIDTH } from './const';

/**
 * メインコンテンツの高さが足りなくてもフッターが中途半端な位置に来ないようにするためのグリッドコンテナ
 * @see https://zenn.dev/catnose99/articles/a873bbbe25b15b
 */
export const DivLayoutContainer = styled.div`
  display: grid;
  place-items: stretch center;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 100%;
  min-height: calc((var(--vh, 1vh) * 100));
`;

export const Main = styled.main`
  width: 100%;
  max-width: ${LAYOUT_CONTAINER_MAX_WIDTH}px;
`;

export const Divider = styled.hr<{ visible: boolean }>`
  width: 100%;
  border: none;
  /* border-top: 1px solid ${COLOR.GRAY100}; */
  border-top: ${({ visible }) => (visible ? `1px solid ${COLOR.GRAY100}` : '0px')};
`;
