import styled from '@emotion/styled';

import { fadeIn } from '@/styles/animation';
import { COLOR } from '@/styles/color';
import { mq } from '@/styles/media';
import { Z_INDEX } from '@/styles/z-index';

export const DivModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${Z_INDEX.MODAL_OVERLAY};

  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${fadeIn} 0.3s ease-out;

  width: 100vw;
  height: calc((var(--vh, 1vh) * 100));
  background-color: ${COLOR.OVERLAY};
`;

export const DivModalWrapper = styled.div<{
  maxWidth: `${number}px` | `${number}%`;
  isMinHeight: boolean;
  isSpFullScreenModal: boolean;
}>`
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;

  // 改行しなければテキストがコンテンツボックスからあふれる場合に、ブラウザーが改行を挿入するように設定
  word-break: break-all;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  padding: 16px;
  background-color: ${COLOR.WHITE};

  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};

  max-height: calc((var(--vh, 1vh) * 100) - 32px);
  min-height: ${({ isMinHeight }) => (isMinHeight ? `calc((var(--vh, 1vh) * 100) - 32px)` : null)};

  ${mq.sp} {
    max-width: ${({ isSpFullScreenModal }) => (isSpFullScreenModal ? `100vw` : `calc(100vw - 48px)`)};
    max-height: ${({ isSpFullScreenModal }) =>
      isSpFullScreenModal ? `calc((var(--vh, 1vh) * 100))` : `calc((var(--vh, 1vh) * 100) - 32px)`};
    min-height: ${({ isMinHeight }) => (isMinHeight ? `calc((var(--vh, 1vh) * 100))` : null)};
  }
`;
