import dynamic from 'next/dynamic';
import { memo } from 'react';

import { IconButton } from '@/components/atoms/icon-button';
import { PortalModal } from '@/components/atoms/portal-modal';
import { Select } from '@/components/forms/select';
import { IconClearButton } from '@/components/icons/clear-button';
import { useResumeCommonPreview } from '@/components/templates/resume-common/common/preview/use-resume-common-preview';
import { RESUME_FONT } from '@/consts/resume';
import { useMatchMedia } from '@/hooks/use-match-media';

import type { ResumeCommonPreviewImageProps } from '@/components/templates/resume-common/common/preview-image';

import { PREVIEW_IMAGE } from './const';
import { usePreviewModal } from './store';
import * as Styled from './style';

const ResumeCommonPreviewImage = dynamic<ResumeCommonPreviewImageProps>(
  async () =>
    await import('@/components/templates/resume-common/common/preview-image').then(
      (module) => module.ResumeCommonPreviewImage,
    ),
  { ssr: false },
);

const _PreviewModal = () => {
  const { isPreviewModalOpen, closePreviewModal } = usePreviewModal();
  const { isPcSize } = useMatchMedia();

  const { resumeCommonPreviewForm, handleResumeFontChange } = useResumeCommonPreview();
  return (
    <PortalModal
      isMinHeight={true}
      isSpFullScreenModal={true}
      maxWidth='90%'
      isModalOpen={isPreviewModalOpen}
      onModalClose={closePreviewModal}
    >
      <Styled.DivButtonHeight>
        <IconButton
          name='PreviewModalを閉じるボタン'
          svgComponent={<IconClearButton width={32} height={32} />}
          onBtnPressed={closePreviewModal}
        />
        <Select
          options={Object.values(RESUME_FONT)}
          value={{ value: resumeCommonPreviewForm.font, label: RESUME_FONT[resumeCommonPreviewForm.font].label }}
          onOptionChange={(option: any) => handleResumeFontChange(option.value)}
        />
      </Styled.DivButtonHeight>

      <Styled.DivPreviewImageHeight>
        <ResumeCommonPreviewImage
          imageWidth={isPcSize ? PREVIEW_IMAGE.PC.imageWidth : PREVIEW_IMAGE.SP.imageWidth}
          imageHeight={isPcSize ? PREVIEW_IMAGE.PC.imageHeight : PREVIEW_IMAGE.SP.imageHeight}
        />
      </Styled.DivPreviewImageHeight>
    </PortalModal>
  );
};

export const PreviewModal = memo(_PreviewModal);
