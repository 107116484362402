import { useRef } from 'react';
import { createPortal } from 'react-dom';

import { isClient } from '@/consts/device';
import { useFocusTrap } from '@/hooks/use-focus-trap';

import type { MouseEvent, TouchEvent, ReactNode } from 'react';

import { SCROLL_MODAL_ID } from './const';
import * as Styled from './style';
import { useModalScrollLock } from './use-modal-scroll-lock';

let rootElement: HTMLDivElement | null;
const TABLET_AND_PC_MODAL_MAX_WIDTH = 600;

type PortalModalProps = {
  /** ※ フォーカス可能な要素を1つ以上格納する必要がある */
  children: ReactNode;
  /** モーダルが開いている時に`true` */
  isModalOpen: boolean;
  /** モーダルを閉じるためのイベントハンドラ */
  onModalClose: () => void;
  /**
   * モーダルの最大値の横幅
   * @default "600px"
   */
  maxWidth?: `${number}px` | `${number}%`;
  /**
   * Storybookで表示したい場合`true`に設定する
   * @default false
   */
  isStorybook?: boolean;
  /**
   * Modalの最小の高さを指定する場合に`true`に設定する
   * @default false
   */
  isMinHeight?: boolean;
  /**
   * SPの場合（画面幅が599px以下の場合）、FullScreenでModalを開くかどうか
   * @default false
   */
  isSpFullScreenModal?: boolean;
  /**
   * モーダルの背景をクリックした時にモーダルを閉じるかどうか
   * @default true
   */
  closeOnOverlayClick?: boolean;
} & JSX.IntrinsicElements['div'];

/** @deprecated shadcn/uiを使用すること */

export const PortalModal = ({
  children,
  isModalOpen,
  onModalClose,
  maxWidth = `${TABLET_AND_PC_MODAL_MAX_WIDTH}px`,
  isStorybook = false,
  isMinHeight = false,
  isSpFullScreenModal = false,
  closeOnOverlayClick = true,
  ...divProps
}: PortalModalProps) => {
  const modalOverlayRef = useRef<HTMLDivElement>(null);

  const handleEventBubbleStop = (event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  useFocusTrap({
    overlayRef: modalOverlayRef,
    isDisplay: isModalOpen,
    onModalClose,
  });

  useModalScrollLock({ modalDisplay: isModalOpen });

  if (!isClient) return null;

  if (isStorybook) {
    rootElement = document.querySelector<HTMLDivElement>('#root');
  } else {
    rootElement = document.querySelector<HTMLDivElement>('#layout-container');
  }

  if (!rootElement || !isModalOpen) return null;

  return createPortal(
    <Styled.DivModalOverlay
      role='dialog'
      ref={modalOverlayRef}
      aria-modal={isModalOpen}
      tabIndex={-1}
      onClick={closeOnOverlayClick ? onModalClose : handleEventBubbleStop}
    >
      <Styled.DivModalWrapper
        role='presentation'
        id={SCROLL_MODAL_ID}
        maxWidth={maxWidth}
        isMinHeight={isMinHeight}
        isSpFullScreenModal={isSpFullScreenModal}
        onClick={handleEventBubbleStop}
        {...divProps}
      >
        {children}
      </Styled.DivModalWrapper>
    </Styled.DivModalOverlay>,
    rootElement,
  );
};
