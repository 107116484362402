export const PREVIEW_IMAGE = {
  PC: {
    imageWidth: 800,
    /** 履歴書のアスペクト比率は「1:1.44」のため */
    imageHeight: 800 * 1.44,
  },
  SP: {
    imageWidth: 320,
    /** 履歴書のアスペクト比率は「1:1.44」のため */
    imageHeight: 320 * 1.44,
  },
} as const;
