import { useAspidaQuery } from '@aspida/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuthState } from '@/components/provider/auth-provider/store';
import { useResumeIdQueryString } from '@/hooks/use-resume-id-query-string';
import { useStorage } from '@/hooks/use-storage';
import { STORAGE_KEY } from '@/hooks/use-storage/type';
import { customApi } from '@/utils/custom-api';

import type { Resume } from '@/api/@types';
import type { RESUME_FONT } from '@/consts/resume';
import type { UserResumeMethodType } from '@/types/user-resume-method-type';

type ResumeCommonPreviewFormType = {
  font: keyof typeof RESUME_FONT;
};

const initialResumeCommonPreviewForm: ResumeCommonPreviewFormType = {
  font: 'timesNewRoman',
};

const STORE_KEY = 'resume-common-preview';

const resumeCommonPreviewAtom = atom({
  key: `${STORE_KEY}/resumeCommonPreviewForm`,
  default: initialResumeCommonPreviewForm,
});

export const useResumeCommonPreview = () => {
  const { jwtToken } = useAuthState();
  const { isReady } = useRouter();
  const { getStorage, setStorage } = useStorage();
  const { resumeId } = useResumeIdQueryString();

  const { data: userResumeData, isLoading: isUserResumeLoading } = useAspidaQuery(
    customApi(jwtToken).user.resumes._resumeId(resumeId ?? ''),
    {
      // 画面をクリックすると自動でfetchが再実行されないように設定
      refetchOnWindowFocus: false,
      enabled: !!jwtToken && !!resumeId,
    },
  );

  const resumeCommonPreviewForm = useRecoilValue(resumeCommonPreviewAtom);
  const setResumeCommonPreviewForm = useSetRecoilState(resumeCommonPreviewAtom);

  // クエリパラメータがなしの場合
  useEffect(() => {
    if (!isReady || resumeId !== undefined) return;

    const { font = 'timesNewRoman' } = getStorage<UserResumeMethodType>(STORAGE_KEY.RESUME_COMMON.CREATE) ?? {};
    setResumeCommonPreviewForm({ font });
  }, [isReady, resumeId]);

  // クエリパラメータがある場合
  useEffect(() => {
    if (!isReady || resumeId === undefined) return;
    if (isUserResumeLoading || userResumeData === undefined) return;

    const { font = 'timesNewRoman' } = userResumeData.resume ?? {};
    setResumeCommonPreviewForm({ font });
  }, [isReady, resumeId, isUserResumeLoading, userResumeData]);

  const handleResumeFontChange = (value: keyof typeof RESUME_FONT) => {
    setResumeCommonPreviewForm((prev) => ({ ...prev, font: value }));
    setStorage(STORAGE_KEY.RESUME_COMMON.CREATE, {
      ...getStorage<Resume>(STORAGE_KEY.RESUME_COMMON.CREATE),
      font: value,
    });
  };

  return {
    resumeCommonPreviewForm,
    handleResumeFontChange,
  };
};
