import dynamic from 'next/dynamic';
import { memo } from 'react';

import { IconButton } from '@/components/atoms/icon-button';
import { PortalModal } from '@/components/atoms/portal-modal';
import { IconClearButton } from '@/components/icons/clear-button';
import { useMatchMedia } from '@/hooks/use-match-media';

import type { ShokumuKeirekiPreviewImageProps } from '@/components/feature/cv/preview/preview-image';

import { PREVIEW_IMAGE } from './const';
import { useShokumuKeirekiPreviewModal } from './store';
import * as Styled from './style';

const ShokumuKeirekiPreviewImage = dynamic<ShokumuKeirekiPreviewImageProps>(
  async () =>
    await import('@/components/feature/cv/preview/preview-image').then((module) => module.ShokumuKeirekiPreviewImage),
  { ssr: false },
);

const _ShokumuKeirekiPreviewModal = () => {
  const { isShokumuKeirekiPreviewModalOpen, closeShokumuKeirekiPreviewModal } = useShokumuKeirekiPreviewModal();
  const { isPcSize } = useMatchMedia();

  return (
    <PortalModal
      isMinHeight={true}
      isSpFullScreenModal={true}
      maxWidth='90%'
      isModalOpen={isShokumuKeirekiPreviewModalOpen}
      onModalClose={closeShokumuKeirekiPreviewModal}
    >
      <Styled.DivButtonHeight>
        <IconButton
          name='職務経歴書のプレビューモーダルを閉じる'
          svgComponent={<IconClearButton width={32} height={32} />}
          onBtnPressed={closeShokumuKeirekiPreviewModal}
        />
      </Styled.DivButtonHeight>

      <Styled.DivPreviewImageHeight>
        <ShokumuKeirekiPreviewImage
          imageWidth={isPcSize ? PREVIEW_IMAGE.PC.imageWidth : PREVIEW_IMAGE.SP.imageWidth}
          imageHeight={isPcSize ? PREVIEW_IMAGE.PC.imageHeight : PREVIEW_IMAGE.SP.imageHeight}
        />
      </Styled.DivPreviewImageHeight>
    </PortalModal>
  );
};

export const ShokumuKeirekiPreviewModal = memo(_ShokumuKeirekiPreviewModal);
