import { useCallback } from 'react';
import { atom, useSetRecoilState, useRecoilValue } from 'recoil';

const STORE_KEY = 'preview-screen';

const previewModalAtoms = {
  /** 職務経歴書のID */
  cvId: atom({
    key: `${STORE_KEY}/cvId`,
    default: '',
  }),
  /** プレビュー画面の開閉フラグ */
  isShokumuKeirekiPreviewModalOpen: atom({
    key: `${STORE_KEY}/isShokumuKeirekiPreviewModalOpen`,
    default: false,
  }),
};

type OpenShokumuKeirekiPreviewModalArgs = {
  cvId: string | undefined;
};

export const useShokumuKeirekiPreviewModal = () => {
  const cvId = useRecoilValue(previewModalAtoms.cvId);
  const setCvId = useSetRecoilState(previewModalAtoms.cvId);

  const isShokumuKeirekiPreviewModalOpen = useRecoilValue(previewModalAtoms.isShokumuKeirekiPreviewModalOpen);
  const setIsShokumuKeirekiPreviewModalOpen = useSetRecoilState(previewModalAtoms.isShokumuKeirekiPreviewModalOpen);

  const openShokumuKeirekiPreviewModal = useCallback((args: OpenShokumuKeirekiPreviewModalArgs) => {
    const { cvId } = args;
    setCvId(cvId ?? '');
    setIsShokumuKeirekiPreviewModalOpen(true);
  }, []);

  const closeShokumuKeirekiPreviewModal = useCallback(() => {
    setIsShokumuKeirekiPreviewModalOpen(false);
  }, []);

  return {
    cvId,
    isShokumuKeirekiPreviewModalOpen,
    openShokumuKeirekiPreviewModal,
    closeShokumuKeirekiPreviewModal,
  };
};
