import { forwardRef, memo } from 'react';

import type { Ref, SVGProps } from 'react';

const LoginPerson = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M14 25.667c6.382 0 11.667-5.296 11.667-11.667 0-6.382-5.296-11.667-11.678-11.667C7.618 2.333 2.333 7.618 2.333 14c0 6.371 5.296 11.667 11.667 11.667Zm0-7.778c-3.443 0-6.085 1.235-7.252 2.608A9.73 9.73 0 0 1 4.29 14c0-5.399 4.3-9.722 9.7-9.722A9.702 9.702 0 0 1 23.722 14a9.607 9.607 0 0 1-2.47 6.497c-1.156-1.373-3.81-2.608-7.252-2.608Zm0-1.944c2.208.022 3.923-1.854 3.923-4.313 0-2.31-1.727-4.22-3.923-4.22-2.196 0-3.935 1.91-3.923 4.22.011 2.46 1.727 4.29 3.923 4.313Z'
      fill='#048573'
    />
  </svg>
);

export const IconLoginPerson = memo(forwardRef(LoginPerson));
