import { createFocusTrap } from 'focus-trap';
import { useEffect } from 'react';

import type { RefObject } from 'react';

type UseFocusTrapOptions = {
  overlayRef: RefObject<HTMLDivElement>;
  isDisplay: boolean;
  onModalClose: () => void;
};

export const useFocusTrap = (args: UseFocusTrapOptions): void => {
  const { overlayRef, isDisplay, onModalClose } = args;

  useEffect(() => {
    if (!isDisplay || overlayRef.current === null) return;

    const trap = createFocusTrap(overlayRef.current, {
      clickOutsideDeactivates: true,
      escapeDeactivates: true,
      returnFocusOnDeactivate: true,
      onDeactivate: onModalClose,
    });

    trap.activate();

    return () => {
      trap.deactivate();
    };
  }, [isDisplay]);
};
