export const RESUME = {
  WIDTH: 800,
} as const;

export const RESUME_FONT = {
  timesNewRoman: { label: '明朝1', value: 'timesNewRoman' },
  kleeOne: { label: '明朝2', value: 'kleeOne' },
  'sans-serif': { label: 'ゴシック', value: 'sans-serif' },
  zenKurenaido: { label: '手書き風1', value: 'zenKurenaido' },
  kiwiMaru: { label: '手書き風2', value: 'kiwiMaru' },
  dotGothic16: { label: '個性的', value: 'dotGothic16' },
} as const;
