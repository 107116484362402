/**
 * 履歴書のアスペクト比率は「1:1.44（横と縦の比率は1：√2）」のため
 * - A4サイズの大きさは横210×縦297mm
 */
export const PREVIEW_IMAGE = {
  PC: {
    imageWidth: 800,
    imageHeight: 800 * 1.44,
  },
  SP: {
    imageWidth: 320,
    imageHeight: 320 * 1.44,
  },
} as const;
