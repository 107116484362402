import { useCallback } from 'react';
import { atom, useSetRecoilState, useRecoilValue } from 'recoil';

const STORE_KEY = 'preview-screen';

const previewModalAtoms = {
  /** 履歴書のID */
  resumeId: atom({
    key: `${STORE_KEY}/resumeId`,
    default: '',
  }),
  /** 履歴書の種別 */
  resumeType: atom({
    key: `${STORE_KEY}/resumeType`,
    default: '',
  }),
  /** プレビュー画面の開閉フラグ */
  isPreviewModalOpen: atom({
    key: `${STORE_KEY}/isPreviewModalOpen`,
    default: false,
  }),
};

type OpenPreviewModalArgs = {
  resumeId: string | undefined;
  resumeType: string | undefined;
};

export const usePreviewModal = () => {
  const resumeId = useRecoilValue(previewModalAtoms.resumeId);
  const setResumeId = useSetRecoilState(previewModalAtoms.resumeId);

  const resumeType = useRecoilValue(previewModalAtoms.resumeType);
  const setResumeType = useSetRecoilState(previewModalAtoms.resumeType);

  const isPreviewModalOpen = useRecoilValue(previewModalAtoms.isPreviewModalOpen);
  const setIsPreviewModalOpen = useSetRecoilState(previewModalAtoms.isPreviewModalOpen);

  const openPreviewModal = useCallback((args: OpenPreviewModalArgs) => {
    const { resumeId, resumeType } = args;
    setResumeId(resumeId ?? '');
    setResumeType(resumeType ?? '');
    setIsPreviewModalOpen(true);
  }, []);

  const closePreviewModal = useCallback(() => {
    setIsPreviewModalOpen(false);
  }, []);

  return {
    resumeId,
    resumeType,
    isPreviewModalOpen,
    openPreviewModal,
    closePreviewModal,
  };
};
