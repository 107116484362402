import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { Button } from '@/components/atoms/button';
import { IconButton } from '@/components/atoms/icon-button';
import { IconLoginPerson } from '@/components/icons/login-person';
import { IconRakurakuLogo } from '@/components/icons/rakuraku-logo';
import { IconUnLoginPerson } from '@/components/icons/un-login-person';
import { authModalAtoms, useAuthModalAction } from '@/components/modals/auth-modal/store';
import { useAuthState } from '@/components/provider/auth-provider/store';
import { useToast } from '@/components/stores/toast/store';
import { PATHS } from '@/consts/path';
import { useResumeDialog } from '@/hooks/use-select-cv/dialog-description/store';
import { useSelectResume } from '@/hooks/use-select-resume';

import * as Styled from './style';

type AuthAction = 'SIGN_UP' | 'SIGN_IN';

export const Header = () => {
  const { replace, push, asPath } = useRouter();
  const { openAuthModal } = useAuthModalAction();
  const { isLoggedIn, jwtToken, setJwtToken, setIsLoggedIn } = useAuthState();
  const { isBrowserSaveChecked } = useResumeDialog();
  const { setResumeAndPreferences } = useSelectResume();
  const { openToastComponent } = useToast();

  const setIsSignUp = useSetRecoilState(authModalAtoms.isSignUp);

  const isToolPage = asPath.includes('resume-common/') || asPath.includes('cv/');
  const isAuthenticated = isLoggedIn && jwtToken;
  const profilePathname = PATHS[isAuthenticated ? 'MY_PAGE' : 'SIGN_IN'];
  const profileHref = { pathname: profilePathname, query: isAuthenticated ? {} : { backUrl: asPath } };

  /** 履歴書の種類が増えてURLが増えるたびに正規表現を追加する必要がある */
  const regex = /^\/resume-common\//;
  /** 指定された文字列が /resume-common/ で始まる場合に true を返す */
  const isResumeCommon = regex.test(asPath);

  const handleAuth = (authAction: AuthAction) =>
    isToolPage
      ? (setIsSignUp(true), openAuthModal())
      : push({ pathname: PATHS[authAction], query: { backUrl: asPath } });

  const handleProfileIconClick = useCallback(
    async () => (isAuthenticated ? void replace(PATHS.MY_PAGE) : void handleAuth('SIGN_IN')),
    [isAuthenticated],
  );

  const logout = useCallback(async () => {
    try {
      await replace(PATHS.TOP);

      setIsLoggedIn(false);
      setJwtToken(undefined);
      openToastComponent({ toastType: 'success', toastText: 'ログアウトしました' });
    } catch (error) {
      openToastComponent({ toastType: 'error', toastText: 'ログアウトに失敗しました' });
    }
  }, []);

  return (
    <Styled.HeaderContainer>
      <Link passHref href={PATHS.TOP}>
        <Styled.AnchorIconWrapper>
          <IconRakurakuLogo width={128} height={32} />
        </Styled.AnchorIconWrapper>
      </Link>

      <Styled.SecContainer>
        {!isResumeCommon && ( // ユーザーが履歴書を作成している最中の場合、履歴書を作るボタンを表示しない
          <Link href={PATHS.RESUME_COMMON.PROFILE} passHref>
            <Styled.AnchorCreateResume onClick={() => setResumeAndPreferences('JIS_RESUME_TITLE')}>
              履歴書を作る
            </Styled.AnchorCreateResume>
          </Link>
        )}

        {!isLoggedIn && ( // ユーザーがログインしていない場合、会員登録ボタンとログインボタンを表示
          <>
            {isToolPage ? (
              <>
                <Styled.RegisterButton type='button' onClick={() => handleAuth('SIGN_UP')}>
                  会員登録
                </Styled.RegisterButton>

                {isBrowserSaveChecked && (
                  <Styled.LoginButton type='button' onClick={() => handleAuth('SIGN_IN')}>
                    ログイン
                  </Styled.LoginButton>
                )}
              </>
            ) : (
              <>
                <Link href={{ pathname: PATHS.SIGN_UP, query: { backUrl: asPath } }} passHref>
                  <Styled.AnchorLink>会員登録</Styled.AnchorLink>
                </Link>

                {isBrowserSaveChecked && (
                  <Link href={{ pathname: PATHS.SIGN_IN, query: { backUrl: asPath } }} passHref>
                    <Styled.AnchorLink>ログイン</Styled.AnchorLink>
                  </Link>
                )}
              </>
            )}
          </>
        )}

        {isLoggedIn && ( // ユーザーがログインしている場合、ログアウトボタンを表示
          <Styled.DivBottomButton>
            <Button fontSize='SIZE_10' type='button' styleTypes='secondary' onBtnPressed={logout}>
              ログアウト
            </Button>
          </Styled.DivBottomButton>
        )}

        {isToolPage ? (
          <IconButton
            name='Profile Icon Button'
            svgComponent={
              isLoggedIn ? <IconLoginPerson width={32} height={32} /> : <IconUnLoginPerson width={32} height={32} />
            }
            onBtnPressed={handleProfileIconClick}
          />
        ) : (
          <Link href={profileHref} passHref>
            <Styled.AnchorIconWrapper>
              {isLoggedIn ? <IconLoginPerson width={32} height={32} /> : <IconUnLoginPerson width={32} height={32} />}
            </Styled.AnchorIconWrapper>
          </Link>
        )}
      </Styled.SecContainer>
    </Styled.HeaderContainer>
  );
};
