import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';
import { CLEAR_BUTTON_MIN_HEIGHT } from '@/styles/height';

export const DivButtonHeight = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  min-height: ${CLEAR_BUTTON_MIN_HEIGHT}px;
  border-bottom: 1px solid ${COLOR.BLACK20};
`;

export const DivPreviewImageHeight = styled.div`
  display: grid;
  place-items: center;
  grid-gap: 32px;
  padding: 32px 0px;
  background-color: ${COLOR.BLACK20};
  height: 100%;
`;
